import React, {useEffect, useState} from "react";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import IndicatorBox from "../../../../../../containers/sustainability/multiStep/IndicatorBoxContainer";
import EditableTableContainer from "../../../../../../containers/sustainability/allocation/offRoad/EditableTable";
import MatchingTablesContainer from "../../../../../../containers/sustainability/allocation/offRoad/MatchingTables";
import OverviewPanel from "./OverviewPanel";
import OverviewTableContainer from "../../../../../../containers/sustainability/allocation/offRoad/OverviewTable";
import OverviewTableFulfillmentContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableFulfillmentContainer";
import OverviewTableConstructionContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableConstructionContainer";
import startAllocation from "../../../../../../api/sustainability/allocationSteps/startAllocation";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    btnContainer: {
      marginBottom: theme.spacing(3),
    },
    btn: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),
      minWidth: "110px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    cardContent: {
      maxHeight: "calc(100vh - 250px)",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
  })
);

interface OffRoadCardEditableTableProps {
  step: number;
  handleNext: () => void;
  handleBack: () => void;
}
interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}


const OffRoadCardEditableTable: React.FC<OffRoadCardEditableTableProps> = ({
  step,
  handleBack,
  handleNext,
}) => {
  const classes = useStyles();
    const [overViewData, setOverViewData] = useState(null);
    const [biotemplates, setBiotemplates] = useState([]);
    const [stepBiotemplates, setStepBiotemplates] = useState([]);
    const [stepOneBiotemplates, setStepOneBiotemplates] = useState([]);
    const [stepThirdBiotemplates, setStepThirdBiotemplates] = useState([]);

    const fetchBiotemplateData = async () => {
        const response = await startAllocation();
        if (response && response.valid) {
            setBiotemplates(response.biotemplates);
            const dieselBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.fuel_type === "Biokomponenter for diesel");
            const advancedBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.double_counted_norway === "Ja");
            setStepOneBiotemplates(dieselBiotemplates)
            setStepThirdBiotemplates(advancedBiotemplates)
            if (step === 1) {
                setStepBiotemplates(dieselBiotemplates)
            } else if (step === 2) {
                setStepBiotemplates(advancedBiotemplates)
            } else {
                setStepBiotemplates(response.biotemplates)
            }
        } else {
            alert("Some error occured");
        }
    };
    useEffect(() => {
        fetchBiotemplateData();
    }, [step]);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.btnContainer}
        >
          <Grid item>
          </Grid>
          <Grid item>
            {step > 0 && (
              <ButtonRounded
                variant="contained"
                className={clsx(classes.btn, classes.btnDark)}
                onClick={handleBack}
              >
                Back
              </ButtonRounded>
            )}
            <ButtonRounded
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleNext}
            >
              Next
            </ButtonRounded>
          </Grid>
        </Grid>
          <Grid
              container
              alignItems="center"
              justify="flex-start"
          >
              <Grid item>
                  {step === 1 && (
                      <>
                          <Typography variant="h4">Allocation of the off-road mandate (10%)</Typography>
                          <Typography variant="body2" >
                              In this step you will allocate the sustainability criteria for the required volumes to fulfill the general off-road mandate. NB! The off-road mandate only accepts biofuels with advanced sustainablity criteria. If additional volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                          </Typography>
                          <br /><br />
                      </>
                  )}
                  {step === 2 && (
                      <>
                          <Typography variant="h4">Allocation of volumes required by customers to be above the mandate</Typography>
                          <Typography variant="body2" >
                              In this step you will allocate the sustainability criteria for the volumes that customers have requested to be above the mandate levels. If there are additional volumes supplied to the market that is a surplus to the mandate levels, this will be allocated in step 4.
                          </Typography>
                          <br /><br />
                      </>
                  )}
                  {step === 4 && (
                      <>
                          <Typography variant="h4">Allocation of volumes not applicable to the mandate</Typography>
                          <Typography variant="body2" >
                              In this step you will allocate the sustainability criteria for potential sold bio volumes that are not applicable to the mandate. This will in practice be all dyed biofuel volumes with raw materials not qualifying to be advanced.
                          </Typography>
                          <br /><br />
                      </>
                  )}
              </Grid>
          </Grid>

          {/*{step === 5 || step === 9 ? (*/}
          {/*    <MatchingTablesContainer sustainable_batches={[]}*/}
          {/*                             allocation_status_volume={0}*/}
          {/*                             allocation_status_ton_equivalence={0} />*/}
          {/*) : (*/}
          {/*    <EditableTableContainer data={[]} activeStep={step} />*/}
          {/*)}*/}
          <EditableTableContainer data={stepBiotemplates} stepTwoData={stepThirdBiotemplates} stepOneData={stepOneBiotemplates} activeStep={step} />
      </CardContent>
    </Card>
  );
};

export default OffRoadCardEditableTable;
