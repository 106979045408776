import React, {useEffect, useState} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../components/texts/parseData";

import SmartTable from "../../../../components/SmartTable/index";
import OverviewTableContainer from "./OverviewTable";
import OverviewPanel
  from "../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewPanel";
import {Typography} from "@material-ui/core";
import {getStepData, putStepOverViewData, postStepBiotemplatesData} from "../../../../api/sustainability/allocation/roadUse/stepData";
import startAllocation from "../../../../api/sustainability/allocationSteps/startAllocation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
  })
);

const tableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "raw_material_origin" },
  { name: "Biocomponent Type", id: "fuel_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "double_counted_norway" },
  { name: "Ghg Reduction", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

const prevTableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "origin" },
  { name: "Biocomponent Type", id: "biocomponent_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "advanced" },
  { name: "Ghg Reduction", id: "ghg_reduction" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

interface Props {
  data: any[];
  stepOneData: any[];
  stepTwoData: any[];
  activeStep: number;
  onUpdateData: (updatedData: any[]) => void;
  updateIsFetching: (value: boolean) => void;
}

interface OverViewParamProps {
  etanol: number;
  etanol_advanced: number;
  fame: number;
  fame_advanced: number;
  hvo_hro: number;
  hvo_hro_advanced: number;
  nafta: number;
  nafta_advanced: number;
  bio_gasoline: number;
  bio_gasoline_advanced: number;
  general_mandate: number;
  gasoline_sub_mandate: number;
  advanced_sub_mandate: number;
  above_mandate_req_by_cust: number;
  above_mandate_after_fulfill: number;
}

interface OverViewReqParamProps {
  general_mandate: number;
  general_req_vol: number;
  general_rel_vol: number;
  advanced_sub_mandate: number;
  advanced_sub_req_vol: number;
  advanced_sub_rel_vol: number;
  gasoline_sub_mandate: number;
  gasoline_req_vol: number;
  gasoline_rel_vol: number;
  above_mandate_req_by_cust: number;
  etanol: number;
  etanol_advanced: number;
  nafta: number;
  nafta_advanced: number;
  bio_gasoline: number;
  bio_gasoline_advanced: number;
  fame: number;
  fame_advanced: number;
  hvo_hro: number;
  hvo_hro_advanced: number;
  above_mandate_after_fulfill: number;
}

interface OverViewProps {
  req_vol: OverViewReqParamProps;
  joint_fulfill: OverViewParamProps;
  allocated_LE: OverViewParamProps;
  allocated_15: OverViewParamProps;
  surplus_from_offroad: OverViewParamProps;
}

const EditableTable: React.FC<Props> = ({
  data,
  stepOneData,
  stepTwoData,
  activeStep,
  onUpdateData,
  updateIsFetching,
}) => {
  const classes = useStyles();
  const [overViewData, setOverViewData] = useState<OverViewProps>({
    req_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      advanced_sub_mandate: 0,
      advanced_sub_req_vol: 0,
      advanced_sub_rel_vol: 0,
      gasoline_sub_mandate: 0,
      gasoline_req_vol: 0,
      gasoline_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      etanol: 0,
      etanol_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      above_mandate_after_fulfill: 0,
    },
    joint_fulfill: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_LE: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_15: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    surplus_from_offroad: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
  });


  const [newData, setNewData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [stepTwosData, setStepTwosData] = useState([]);
  const [stepThreeData, setStepThreeData] = useState([]);
  const [stepFourData, setStepFourData] = useState([]);
  const [stepFIveData, setStepFiveData] = useState([]);
  const [stepSixData, setStepSixData] = useState([]);
  const [biotemplatesData, setBiotemplatesData] = useState([]);

  const updateReqVol = (newValues: Partial<OverViewReqParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      req_vol: { ...prevData.req_vol, ...newValues },
    }));
  };

  const updateJointFulfillVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      joint_fulfill: { ...prevData.joint_fulfill, ...newValues },
    }));
  };

  const updateAllocatedLEVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_LE: { ...prevData.allocated_LE, ...newValues },
    }));
  };

  const updateAllocated15Vol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_15: { ...prevData.allocated_15, ...newValues },
    }));
  };

  const updateSurplusFromOffroadVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      surplus_from_offroad: { ...prevData.surplus_from_offroad, ...newValues },
    }));
  };

  const getAllocated = (row: any) => row.allocated_quantity;
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    const { value } = event.target;
    const newData = { ...row, allocated_quantity: value };

    // Assuming you are updating some state here
    setNewData((prevRows) => {
      const updatedRowsCopy = [...prevRows];
      // @ts-ignore
      const rowIndex = updatedRowsCopy.findIndex((r) => r.id === row.id);

      if (rowIndex !== -1) {
        // @ts-ignore
        updatedRowsCopy[rowIndex] = newData;
      }

      return updatedRowsCopy;
    });
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    handleOnChange(event, row);
  };

  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const isDiesel = (row: any) => {
    return row.fuel_type === "Biokomponenter for diesel";
  };

  function isNonEmptyArrayOrObject(value: any): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length > 0;
    }

    return false;
  }

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  };

  const getData = (data: any[], activeStep: number) => {
    return data;
  };

  const fetchBiotemplateData = async (activeStep: number, currentStepData: any[]) => {
    const response = await getStepData(activeStep);
    if (response) {
      if ('data' in response) {
        setStepData(response.data);

        if ("req_vol" in response.data) {
          updateReqVol(response.data.req_vol);
        }
        if ("joint_fulfill" in response.data) {
          updateJointFulfillVol(response.data.joint_fulfill)
        }
        if ("allocated_LE" in response.data) {
          updateAllocatedLEVol(response.data.allocated_LE)
        }
        if ("allocated_15" in response.data) {
          updateAllocated15Vol(response.data.allocated_15)
        }
        if ("surplus_from_offroad" in response.data) {
          updateSurplusFromOffroadVol(response.data.surplus_from_offroad)
        }

        if ( "table_from_step_two" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_two)) {
          if (Array.isArray(response.data.table_from_step_two)) {
            setStepTwosData(response.data.table_from_step_two);
          } else {
            setStepTwosData(Object.values(response.data.table_from_step_two));
          }
        }

        if ( "table_from_step_three" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_three)) {
          if (Array.isArray(response.data.table_from_step_three)) {
            setStepThreeData(response.data.table_from_step_three);
          } else {
            setStepThreeData(Object.values(response.data.table_from_step_three));
          }
        }

        if ( "table_from_step_four" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_four)) {
          if (Array.isArray(response.data.table_from_step_four)) {
            setStepFourData(response.data.table_from_step_four);
          } else {
            setStepFourData(Object.values(response.data.table_from_step_four));
          }
        }

        if ( "table_from_step_five" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_five)) {
          if (Array.isArray(response.data.table_from_step_five)) {
            setStepFiveData(response.data.table_from_step_five);
          } else {
            setStepFiveData(Object.values(response.data.table_from_step_five));
          }
        }

        if ( "table_from_step_six" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_six)) {
          if (Array.isArray(response.data.table_from_step_six)) {
            setStepSixData(response.data.table_from_step_six);
          } else {
            setStepSixData(Object.values(response.data.table_from_step_six));
          }
        }

        let mappedData;

        if (activeStep === 1 && "table_from_step_two" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_two);
        } else if (activeStep === 2 && "table_from_step_three" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_three);
        } else if (activeStep === 3 && "table_from_step_four" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_four);
        } else if (activeStep === 4 && "table_from_step_five" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_five);
        } else if (activeStep === 5 && "table_from_step_six" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_six);
        } else {
          mappedData = currentStepData;
        }
        // @ts-ignore
        setNewData(mappedData);
      } else {
        setStepData([]);
      }
    } else {
      alert("Some error occured");
    }
  };

  const getMappedData = (existingData: any[], apiData: any[]) => {
    const updatedData = existingData.map((item) => {
      // @ts-ignore
      const matchingApiEntry = Object.values(apiData).find(
          // @ts-ignore
          (apiItem) => apiItem.biotemplate_id === String(item.id)
      );

      if (matchingApiEntry) {
        // Update the allocated_quantity
        // @ts-ignore
        return { ...item, allocated_quantity: matchingApiEntry.allocated_quantity };
      }

      return item;
    });

    return updatedData;
  };

  useEffect(() => {
    updateIsFetching(true);
    let newDataForStep;

    switch (activeStep) {
      case 1:
        newDataForStep = stepOneData;
        break;
      case 2:
        newDataForStep = stepTwoData;
        break;
      default:
        newDataForStep = data;
        break;
    }

    // @ts-ignore
    setNewData(newDataForStep);
    fetchBiotemplateData(activeStep, newDataForStep);
    updateIsFetching(false);
  }, [data, stepOneData, stepTwoData, activeStep]);


  useEffect(() => {
    const newArray = {
      // @ts-ignore
      biotemplates: newData.filter(item => item.allocated_quantity > 0).map(item => ({id: item.id, allocated_quantity: item.allocated_quantity}))
    };
    // @ts-ignore
    onUpdateData(newArray)
  }, [activeStep, newData]);

  return (
      <>
        <div className={classes.tableContainer}>
          {activeStep > 1 && (
              <>
                <Typography variant="h4">Step 2 Data</Typography><br />
                <SmartTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepTwosData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 2 && (
              <>
                <Typography variant="h4">Step 3 Data</Typography><br />
                <SmartTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepThreeData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 3 && (
              <>
                <Typography variant="h4">Step 4 Data</Typography><br />
                <SmartTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepFourData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          {activeStep > 4 && (
              <>
                <Typography variant="h4">Step 5 Data</Typography><br />
                <SmartTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepFIveData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          {activeStep > 5 && (
              <>
                <Typography variant="h4">Step 6 Data</Typography><br />
                <SmartTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepSixData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          <Typography variant="h4">Allocation Table</Typography><br />
          <SmartTable
            headCells={tableHeadings}
            // rows={data.length === 0 ? null : getData(data, activeStep)}
            // rows={getData(data, activeStep)}
            rows={getData(newData, activeStep)}
            editableProps={{
              id: "allocated_quantity",
              getValue: getAllocated,
              onChange: handleOnChange,
              onBlur: handleOnBlur,
            }}
            verticalOverflow={true}
            whiteHeader={true}
          />
        </div>
        <OverviewPanel className={classes.overviewPanel}>
          <OverviewTableContainer data={overViewData} step={activeStep} />
        </OverviewPanel>
      </>
  );
};

export default EditableTable;
