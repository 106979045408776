import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {State} from "../../../../reducers/reducer";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ButtonRounded from "../../../../components/ButtonRounded";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";

import {ISettingProps} from "../../../../components/modal/Interfaces";

import ModalAddEdit from "../../../../components/modal/ModalAddEdit";
import ModalDelete from "../../../../components/modal/ModalDelete";

import {FormattedMessage} from "react-intl";
import useModal from "../../../../components/modal/useModal";

import parseData from "../../../../components/texts/parseData";
import {getAppVariables} from "../../../../reducers/settings/app_variables";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backBtn: {
            textTransform: "none",
            marginBottom: theme.spacing(2),
        },
        titleBar: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
        flagText: {
            maxWidth: "650px",
            paddingBottom: theme.spacing(1),
            color: theme.palette.error.main,
        },
        inputSearch: {
            marginRight: theme.spacing(2),
            "& .MuiInput-root": {
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: "2px 8px",
            },
            [theme.breakpoints.down("md")]: {
                width: "200px",
            },
        },
        responsiveTable: {
            minWidth: "425px",
            overflowX: "auto",
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "inherit",
                fontWeight: "bold",
                padding: "10px 0px",
            },
            "& .MuiTableRow-head:nth-child(odd)": {
                "& .MuiTableCell-head": {
                    padding: "0px",
                    borderRight: `4px solid ${theme.palette.common.white}`,
                },
            },
            "& .MuiTableCell-body": {
                minWidth: "130px",
                padding: "5px 10px",
            },
        },
        iconDelete: {
            color: theme.palette.error.main,
        },
        avatar: {
            width: "30px",
            height: "30px",
        },
    })
);


interface StateProps {
    app_variables: any;
    dispatch?: any; // fix warning
}

const mapStateToProps = (state: State) => ({
    app_variables: state.settings.app_variables.data,
});

const SettingView: React.FC<ISettingProps & StateProps> = ({
                                                               app_variables,
                                                               ...props
                                                           }) => {
    const {openModalId, toggle} = useModal();
    const [contentList, setContentList] = useState<any>(null);
    const [showFlagText, setShowFlagText] = useState<boolean>(false);
    const [selectedSetting, setSelectedSetting] = useState<any>(null);

    const [searchText, setSearchText] = useState<string>("");
    const [selectedMandateType, setSelectedMandateType] = useState<string>("road-use")
    const [lastClicked, setLastClicked] = useState<string>("road-use"); // State to track the last clicked button

    const activeButtonStyle: React.CSSProperties = {
        fontWeight: "bold",
    };


    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const mandateType: { [key: string]: any } = {};
    mandateType['road-use'] = "road-use"
    mandateType['off-road'] = "off-road"
    mandateType['marine'] = "marine"

    const settingPaths: { [key: string]: any } = {};
    settingPaths["government_requirements"] = "/settings/government_requirements"

    let filteredContentList = Array.isArray(contentList)
        ? contentList.filter((row) => {
            let filteredOut = true;
            Object.values(row).map((val) => {
                if (String(val).toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    filteredOut = false;
            });
            return !filteredOut;
        })
        : [];

    const openModal = (id: string, selectedSetting?: any) => {
        if (selectedSetting) {
            setSelectedSetting(selectedSetting);
        }
        toggle(id);
    };

    const closeModal = () => {
        fetchData();
        setSelectedSetting(null);
        dispatch(getAppVariables());
        toggle();
    };

    const fetchData = async () => {
        const response = await props.api.get();
        if (response.valid) {
            setContentList(response.data);
            setShowFlagText(response.info_text_flag);
        }
    };

    const getInitParams: any = () => {
        var initValues = {};
        if (Array.isArray(props.table))
            props.table.map((elmt: any, elIndex) => {
                var initVal = elmt.initValue;
                if (Array.isArray(elmt.initValue))
                    initVal = app_variables[elmt.initValue[0]].values[elmt.initValue[1]];
                Object.assign(initValues, {[elmt.id]: initVal});
            });
        return initValues;
    };

    const initParams = getInitParams();

    useEffect(() => {
        fetchData();
        dispatch(getAppVariables());
    }, []);
    let current_path = window.location.pathname
    if (current_path === settingPaths['government_requirements']) {
        filteredContentList = Array.isArray(filteredContentList)
            ? filteredContentList.filter((item) => {
                return item.mandate_type === selectedMandateType;
            })
            : [];
    }
    const hideColumns = selectedMandateType == mandateType['road-use'];
    if(hideColumns){
        return (
            <div>
                <Button
                    color="primary"
                    className={classes.backBtn}
                    onClick={() => history.goBack()}
                >
                    <Icon color="primary">arrow_back</Icon>
                    <Typography variant="h6">
                        &nbsp; <FormattedMessage id="settingsView.back"/>
                    </Typography>
                </Button>
                <Card>
                    <CardContent>
                        {/* Top Search Bar */}
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            className={classes.titleBar}
                        >
                            <Grid item>
                                <Typography variant="h5">{props.name}</Typography>
                                {current_path === settingPaths.government_requirements && (
                                    <div>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedMandateType(mandateType['road-use']);
                                                setLastClicked(mandateType['road-use']); // Set the last clicked button
                                            }}
                                            style={lastClicked === mandateType['road-use'] ? activeButtonStyle : {}}
                                        >
                                            {mandateType['road-use']}
                                        </ButtonRounded>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"

                                            onClick={() => {
                                                setSelectedMandateType(mandateType['off-road']);
                                                setLastClicked(mandateType['off-road']); // Set the last clicked button
                                            }}
                                            style={{
                                                ...(lastClicked === mandateType['off-road'] ? activeButtonStyle : {}),
                                                margin: '10px',
                                            }}

                                        >
                                            {mandateType['off-road']}
                                        </ButtonRounded>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedMandateType(mandateType['marine']);
                                                setLastClicked(mandateType['marine']); // Set the last clicked button
                                            }}
                                            style={lastClicked === mandateType['marine'] ? activeButtonStyle : {}}
                                        >
                                            {mandateType['marine']}
                                        </ButtonRounded>
                                    </div>)}

                                {showFlagText && (
                                    <Typography className={classes.flagText} variant="body1">
                                        <FormattedMessage id="settingsView.flagText"/>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <TextField
                                            placeholder="Search"
                                            className={classes.inputSearch}
                                            value={searchText}
                                            onChange={(e: any) => setSearchText(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <SearchIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => openModal("create")}
                                        >
                                            {"Add new "}
                                            {props.name}
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Users Table */}
                        <div className={classes.responsiveTable}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {Array.isArray(props.table) &&
                                            props.table.map((elmt: any, elIndex) => (
                                                <TableCell key={elIndex}>{elmt.name}</TableCell>
                                            ))}
                                        <TableCell> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(filteredContentList) &&
                                        filteredContentList.map((row: any, rowIndex) => (
                                            <TableRow
                                                key={row.id}
                                                style={{
                                                    backgroundColor: props.table.find(
                                                        (elmt) => elmt.boolean_value && !row[elmt.id]
                                                    )
                                                        ? "#ffeed7"
                                                        : "",
                                                }}
                                            >
                                                {Array.isArray(props.table) &&
                                                    props.table.map((elmt: any, elIndex) => (
                                                        <TableCell
                                                            key={elIndex}
                                                            style={{
                                                                fontWeight:
                                                                    (elmt.boolean_value && !row[elmt.id]) ||
                                                                    (elmt.initValue && initParams[elmt.id] != row[elmt.id])
                                                                        ? "bold"
                                                                        : "normal",
                                                                color: elmt.boolean_value && !row[elmt.id] ? "#ffac3a" : "inherit",
                                                            }}
                                                        >
                                                            {elmt.boolean_value
                                                                ? row[elmt.id]
                                                                    ? elmt.boolean_value[0]
                                                                    : elmt.boolean_value[1]
                                                                : elmt.decimals_format
                                                                    ? parseData(row[elmt.id], elmt.decimals_format)
                                                                    : row[elmt.id]}
                                                        </TableCell>
                                                    ))}
                                                <TableCell align={"right"}>
                                                    <IconButton color="primary" onClick={() => openModal("edit", row)}>
                                                        <EditOutlinedIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton
                                                        className={classes.iconDelete}
                                                        onClick={() => openModal("delete", row)}
                                                    >
                                                        <DeleteOutlinedIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>

                {
                    selectedSetting && (
                        <>
                            <ModalDelete
                                name={props.name}
                                id={selectedSetting.id}
                                Delete={props.api.Delete}
                                open={openModalId === "delete"}
                                onClose={closeModal}
                            />
                            <ModalAddEdit
                                name={props.name}
                                open={openModalId === "edit"}
                                onSave={props.api.Edit}
                                editParams={selectedSetting}
                                params={props.table}
                                onClose={closeModal}
                                mandateType={selectedMandateType}
                            />
                        </>
                    )
                }
                <ModalAddEdit
                    name={props.name}
                    open={openModalId === "create"}
                    onSave={props.api.create}
                    initParams={initParams}
                    params={props.table}
                    onClose={closeModal}
                    mandateType={selectedMandateType}
                />
            </div>
        );
    }
    else {

        return (
            <div>
                <Button
                    color="primary"
                    className={classes.backBtn}
                    onClick={() => history.goBack()}
                >
                    <Icon color="primary">arrow_back</Icon>
                    <Typography variant="h6">
                        &nbsp; <FormattedMessage id="settingsView.back"/>
                    </Typography>
                </Button>
                <Card>
                    <CardContent>
                        {/* Top Search Bar */}
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            className={classes.titleBar}
                        >
                            <Grid item>
                                <Typography variant="h5">{props.name}</Typography>
                                {current_path === settingPaths.government_requirements && (
                                    <div>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedMandateType(mandateType['road-use']);
                                                setLastClicked(mandateType['road-use']); // Set the last clicked button
                                            }}
                                            style={lastClicked === mandateType['road-use'] ? activeButtonStyle : {}}
                                        >
                                            {mandateType['road-use']}
                                        </ButtonRounded>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"

                                            onClick={() => {
                                                setSelectedMandateType(mandateType['off-road']);
                                                setLastClicked(mandateType['off-road']); // Set the last clicked button
                                            }}
                                            style={{
                                                ...(lastClicked === mandateType['off-road'] ? activeButtonStyle : {}),
                                                margin: '10px',
                                            }}

                                        >
                                            {mandateType['off-road']}
                                        </ButtonRounded>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedMandateType(mandateType['marine']);
                                                setLastClicked(mandateType['marine']); // Set the last clicked button
                                            }}
                                            style={lastClicked === mandateType['marine'] ? activeButtonStyle : {}}
                                        >
                                            {mandateType['marine']}
                                        </ButtonRounded>
                                    </div>)}

                                {showFlagText && (
                                    <Typography className={classes.flagText} variant="body1">
                                        <FormattedMessage id="settingsView.flagText"/>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <TextField
                                            placeholder="Search"
                                            className={classes.inputSearch}
                                            value={searchText}
                                            onChange={(e: any) => setSearchText(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <SearchIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ButtonRounded
                                            color="primary"
                                            variant="contained"
                                            onClick={() => openModal("create")}
                                        >
                                            {"Add new "}
                                            {props.name}
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Users Table */}
                        <div className={classes.responsiveTable}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {Array.isArray(props.table) &&
                                            props.table.map((elmt: any, elIndex) => (
                                                // Check if elIndex is 2 (third column) or 4 (fifth column)
                                                (elIndex !== 2 && elIndex !== 4 && !hideColumns) ? (
                                                    <TableCell key={elIndex}>
                                                        {elIndex === 3 ? 'General requirement (%)' : elmt.name}
                                                    </TableCell>
                                                ) : null
                                            ))}
                                        <TableCell> </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Array.isArray(filteredContentList) &&
                                        filteredContentList.map((row: any, rowIndex) => (
                                            <TableRow
                                                key={row.id}
                                                style={{
                                                    backgroundColor: props.table.find(
                                                        (elmt) => elmt.boolean_value && !row[elmt.id]
                                                    )
                                                        ? "#ffeed7"
                                                        : "",
                                                }}
                                            >
                                                {Array.isArray(props.table) &&
                                                    props.table.map((elmt: any, elIndex) => (
                                                        // Check if elIndex is 2 (third column) or 4 (fifth column)
                                                        (elIndex !== 2 && elIndex !== 4 && !hideColumns) ? (
                                                            <TableCell
                                                                key={elIndex}
                                                                style={{
                                                                    fontWeight:
                                                                        (elmt.boolean_value && !row[elmt.id]) ||
                                                                        (elmt.initValue && initParams[elmt.id] != row[elmt.id])
                                                                            ? "bold"
                                                                            : "normal",
                                                                    color: elmt.boolean_value && !row[elmt.id] ? "#ffac3a" : "inherit",
                                                                }}
                                                            >
                                                                {elmt.boolean_value
                                                                    ? row[elmt.id]
                                                                        ? elmt.boolean_value[0]
                                                                        : elmt.boolean_value[1]
                                                                    : elmt.decimals_format
                                                                        ? parseData(row[elmt.id], elmt.decimals_format)
                                                                        : row[elmt.id]}
                                                            </TableCell>
                                                        ) : null
                                                    ))}
                                                <TableCell align={"right"}>
                                                    <IconButton color="primary" onClick={() => openModal("edit", row)}>
                                                        <EditOutlinedIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton
                                                        className={classes.iconDelete}
                                                        onClick={() => openModal("delete", row)}
                                                    >
                                                        <DeleteOutlinedIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>

                {
                    selectedSetting && (
                        <>
                            <ModalDelete
                                name={props.name}
                                id={selectedSetting.id}
                                Delete={props.api.Delete}
                                open={openModalId === "delete"}
                                onClose={closeModal}
                            />
                            <ModalAddEdit
                                name={props.name}
                                open={openModalId === "edit"}
                                onSave={props.api.Edit}
                                editParams={selectedSetting}
                                params={props.table}
                                onClose={closeModal}
                                mandateType={selectedMandateType}
                            />
                        </>
                    )
                }
                <ModalAddEdit
                    name={props.name}
                    open={openModalId === "create"}
                    onSave={props.api.create}
                    initParams={initParams}
                    params={props.table}
                    onClose={closeModal}
                    mandateType={selectedMandateType}
                />
            </div>
        );
    }
};

export default connect(mapStateToProps)(SettingView);