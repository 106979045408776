import React, {Component, useEffect, useState, useRef} from 'react';
import ButtonRounded from "../../../components/ButtonRounded";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ToggleButton from "../../../components/ToggleButton";
import ModalAddEditCollab from "../../../views/DashboardView/CardView/AllocationView/ModalAddEditCollab";
import ModalDeleteCollab from "../../../views/DashboardView/CardView/AllocationView/ModalDeleteCollab";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import Pagination from "../../../components/Pagination";
import ParseData from "../../../components/texts/parseData";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FormattedMessage} from "react-intl";
// @ts-ignore
import { State } from "../../../reducers/reducer";
import { useSelector } from 'react-redux';
import getAllocationPageData from "../../../api/sustainability/allocation/collaboration/get";
import {useHistory, useRouteMatch} from "react-router-dom";
import {startAllocation} from "../../../reducers/sustainability/allocation/multiStep";
import { hasRoadData, deleteRoadUseData } from "../../../api/sustainability/allocation/roadUse/data";
import { hasOffRoadData, deleteOffRoadUseData } from "../../../api/sustainability/allocation/offRoadUse/data";
import { hasMarineData, deleteMarineData } from "../../../api/sustainability/allocation/marineUse/data";
// @ts-ignore
import { toggleSimulation } from "../../../reducers/sustainability/main";
import { useDispatch } from 'react-redux';
import resetAllocation from "../../../api/sustainability/allocation/roadUse/stepData";
import getDownloadReport, {Tfiletype} from "../../../api/sustainability/reports/getDownloadReport";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },
        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: 'space-between',
        },
        cardAllocation: {
            flex: '1', // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: '1', // Takes 1/3 of the available space
            margin: '25px 0', // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "11px",
                fontWeight: 700,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
    })
);


const MandateOverviewContainer: React.FC = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const isSimulation = useSelector((state: State) => state.sustainability.main.isSimulation);
    const activePeriod = useSelector((state: State) => state.sustainability.main.activePeriod);

    const [isRoadUseToggleButtonEnabled, setIsRoadUseToggleButtonEnabled] = useState(true);
    const [isOffRoadToggleButtonEnabled, setIsoffRoadToggleButtonEnabled] = useState(true);
    const [isMarineToggleButtonEnabled, setIsMarineToggleButtonEnabled] = useState(true);

    const [areButtonsVisibleRoadUse, setAreButtonsVisibleRoadUse] = useState(true);
    const [areButtonsVisibleOffRoad, setAreButtonsVisibleOffRoad] = useState(true);
    const [areButtonsVisibleMarine, setAreButtonsVisibleMarine] = useState(true);
    const [marineDataId, setmarineDataId] = useState<any>(null);
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [offRoadUseId, setOffRoadUseId] = useState<any>(null);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [
        showConfirmDeliveryModal,
        setShowConfirmDeliveryModal,
    ] = useState<boolean>(false);

    const openErrorModal = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorModal(true);
    };
    const closeErrorModal = () => {
        setErrorMessage("");
        setShowErrorModal(false);
    };

    const openConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(true);
    };

    const closeConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(false);
    };

    const handleRoadUseVolume = () => {
        if (match) {
            history.push(`${match.url}roadusevolumes`);
        }
    };
    //Change the function name later
    const handleRoadUseReport = async () => {
        const response = await resetAllocation();
        if (response && response.success) {
            if (match) {
                //startAllocation();
                history.push(`${match.url}roaduse/allocation`);
            }
        } else {
            alert('Error occured when reseting allocation');
        }

    };
    const handleOffRoadReport = () => {
        if (match) {
            //startAllocation();
            history.push(`${match.url}offroad/allocation`);
        }
    };
    const handleOffRoadVolume = () => {
        if (match) {
            history.push(`${match.url}offroadvolumes`);
        }
    };
    const handleMarineVolume = () => {
        if (match) {
            history.push(`${match.url}marinevolumes`);
        }
    };
    const handleContinueAllocation = () => {
        if (match) {
            history.push(`${match.url}/start`);
        }
    };
    const handleStartAllocation = () => {
        if (match) {
            startAllocation();
            history.push(`${match.url}allocation/start`);
        }
    };
    const handleRoadUseAddVolume = () => {
        if (match) {
            //startAllocation();
            history.push(`${match.url}allocation/roaduse`);
        }
    };

    const handleOffRoadAddVolume = () => {
        if (match) {
            //startAllocation();
            history.push(`${match.url}allocation/offroad`);
        }
    };

    const checkData = async () => {
        const roadUse = await hasRoadData();
        setRoadUseId(roadUse ? roadUse.id : null);

        const offRoadUse = await hasOffRoadData();
        setOffRoadUseId(offRoadUse ? offRoadUse.id : null);

        // const marine = await hasMarineData();
        // setmarineDataId(marine ? marine.id : null);
    };

    const deleteData = async ( type: string, id: any ) => {
        switch (type) {
            case "road-use" :
                await deleteRoadUseData(id);
                break;
            case "off-road-use" :
                await deleteOffRoadUseData(id);
                break;
            case "marine" :
                await deleteMarineData(id);
                break;
            default :
                console.log("no matched data");
        }

        checkData();
    };
    const isMounted = useRef(true);

    const handleDownload = async (type: Tfiletype) => {
        const response = await getDownloadReport({
            type,
            // @ts-ignore
            taxYear: activePeriod.year,
        });
        if (response === null) {
            openErrorModal(
                "Failed to download file: Possible errors: no file for selected year or other errors."
            );
        }

        if (response !== null) {
            // emulate file download by clicking on an <a /> link
            const url = window.URL.createObjectURL(response);
            const fileName =
                type.indexOf("sub") > -1
                    ? "sustainability-subreport"
                    : "sustainability-report";
            const fileExt = type.indexOf("excel") > -1 ? "xlsx" : "json";

            const a = document.createElement("a");
            a.href = url;
            // @ts-ignore
            a.download = `${fileName}-${activePeriod.year}.${fileExt}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };

    useEffect(() => {
        setAreButtonsVisibleRoadUse(isRoadUseToggleButtonEnabled);
        setAreButtonsVisibleOffRoad(isOffRoadToggleButtonEnabled);
        setAreButtonsVisibleMarine(isMarineToggleButtonEnabled);
    }, [isSimulation,isRoadUseToggleButtonEnabled, isOffRoadToggleButtonEnabled, isMarineToggleButtonEnabled]);

    useEffect(() => {
        let params = {
            allocation: isSimulation ? "Simulation" : "Reporting",
            fulfilment: "own",
            // @ts-ignore
            taxYearId: activePeriod.id,
        };

        getAllocationPageData(params);
        checkData();
    }, [isSimulation, activePeriod]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            checkData();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div>
            <h1>Bio mandate reporting</h1>
            <div className={classes.cardContainer}>
                <Card className={`${classes.card} ${classes.cardReport}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.toggleCardTitle}>
                            <FormattedMessage id="allocationView.mainView.allocationMode"/>
                        </Typography>

                        <ToggleButton
                            labels={["Reporting", "Simulation"]}
                            defaultLabelIndex={isSimulation ? 1 : 0}
                            handleChange={() => dispatch(toggleSimulation())}
                        />
                    </CardContent>
                </Card>
                <Card className={`${classes.card} ${classes.cardAllocation}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <p>Report File and Actions</p>
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleDownload("report_excel")}
                                >
                                    Download Excel Rep
                                </ButtonRounded>
                            </Grid>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleDownload("report_json")}
                                >
                                    Download JSON File
                                </ButtonRounded>
                            </Grid>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => console.log("clicked")}
                                    //disabled={!collectiveFulfillement}
                                    //className={classes.btnDownloads}
                                >
                                    Confirm Delivery
                                </ButtonRounded>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

            <Card>
                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Road use mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleRoadUse && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleRoadUse && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId !== null}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={roadUseId === null}
                                            onClick={() => deleteData("road-use", roadUseId)}
                                        >
                                            Delete Volume
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseReport}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null}
                                        >
                                                Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseReport}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseReport}
                                            disabled={roadUseId === null}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={()=>console.log("clicked")}
                                            disabled={roadUseId === null}
                                        >
                                            View Draft Report
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>)}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>

                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Enabled", "Disabled"]}
                                    defaultLabelIndex={isRoadUseToggleButtonEnabled ? 0 : 1}
                                    handleChange={() => setIsRoadUseToggleButtonEnabled(!isRoadUseToggleButtonEnabled)}
                                />
                                </div>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isSimulation ? 1 : 0}
                                    handleChange={() => console.log('change')}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Card>


            <Card>
                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Off-road mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleOffRoad && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleOffRoad && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId !== null}
                                        >
                                            Add Volumes
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Edit Volumes
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => deleteData("off-road-use", offRoadUseId)}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={()=>console.log("clicked")}
                                            disabled={offRoadUseId === null}
                                        >
                                            View Draft
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>)}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin}>
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isOffRoadToggleButtonEnabled ? 0 : 1}
                                        handleChange={() => setIsoffRoadToggleButtonEnabled(!isOffRoadToggleButtonEnabled)}
                                    />
                                </div>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isSimulation ? 1 : 0}
                                    handleChange={() => console.log('change')}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Card>


            <Card>
                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Marine mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleMarine && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleMarine && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId !== null}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => deleteData("marine", marineDataId)}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Delete Volume
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={()=>console.log("click")}
                                            disabled={marineDataId === null}
                                        >
                                            View Draft
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>)}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin} >
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isMarineToggleButtonEnabled ? 0 : 1}
                                        handleChange={() => setIsMarineToggleButtonEnabled(!isMarineToggleButtonEnabled)}
                                    />
                                </div>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isSimulation ? 1 : 0}
                                    handleChange={() => console.log('change')}

                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Card>
        </div>


    );
};
export default MandateOverviewContainer;
