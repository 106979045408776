import { combineReducers } from "redux";
import offRoadMultiStepReducer, { OffRoadMultiStepState } from "./multiStep/OffRoadIndex";
import soldVolumesReducer, { SoldVolumesState } from "./soldVolumes";
import ConstructionDieselVolumesReducer, {
    ConstructionDieselVolumesState,
} from "./constructionDieselVolumes";

export interface OffRoadAllocationState {
    OffRoadMultiStep: OffRoadMultiStepState;
    soldVolumes: SoldVolumesState;
    constructionDieselVolumes: ConstructionDieselVolumesState;
}

export default combineReducers({
    OffRoadMultiStep: offRoadMultiStepReducer,
    soldVolumes: soldVolumesReducer,
    constructionDieselVolumes: ConstructionDieselVolumesReducer,
});
