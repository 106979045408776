/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const getStepData = async (activeStep: number) => {
  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;
    case 5:
      stepreturn = "step_six";
      break;

    default:
      stepreturn = "step_two";
      break;
  }

  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/roaduse_report_generate/${stepreturn}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const putStepOverViewData = async (activeStep: number, params: any) => {
  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;
    case 5:
      stepreturn = "step_six";
      break;

    default:
      stepreturn = "step_two";
      break;
  }
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/roaduse_report_generate/${stepreturn}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const postStepBiotemplatesData = async (activeStep: number, params: any) => {
  const token = getToken();
  let stepreturn;

  switch (activeStep) {
    case 1:
      stepreturn = "step_two";
      break;
    case 2:
      stepreturn = "step_three";
      break;
    case 3:
      stepreturn = "step_four";
      break;
    case 4:
      stepreturn = "step_five";
      break;
    case 5:
      stepreturn = "step_six";
      break;

    default:
      stepreturn = "step_two";
      break;
  }
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/roaduse_report_generate/${stepreturn}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};


export const resetAllocation = async () => {
  const token = getToken();

  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/roaduse_report_generate/reset_allocation`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default resetAllocation;
