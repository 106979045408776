import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepLabel";
import StepIcon from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepIcon";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepConnector";
import CustomerRequirements from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/CustomerRequirements";
import LoadingView from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/LoadingView";
import SuccessView from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/SuccessView";
import MainCardTables from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OffRoadMainCardTables";
import StatusModeType from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StatusModeType";
import { State } from "../../../../reducers/reducer";
import { useSelector } from 'react-redux';
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
          display: "flex",
      },
      textContainer: {
          marginRight: theme.spacing(4),
      },
      textPrimary: {
          color: theme.palette.primary.main,
          marginLeft: theme.spacing(1),
      },
    statusModeType: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    stepper: {
      backgroundColor: "inherit",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
      width: "calc(100% + 54px)",
      marginLeft: "-30px",
    },
  })
);


const OffRoadContainer: React.FC = () => {
  const classes = useStyles();

    const isSimulation: any = useSelector((state: State) => state.sustainability.main.isSimulation);
    const isJoint: any = useSelector((state: State) => state.sustainability.main.isJoint);
    const periodName: any = useSelector((state: State) => state.sustainability.main.activePeriod);
    const [activeStep, setActiveStep] = useState(1);
    const [steps, setSteps] = useState<{}>({
        0: {
            name: "Joint Fulfillment",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: true,
            data: [],
            allocated: {},
        },
        1: {
            name: "General Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        2: {
            name: "Reserved Above the Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        3: {
            name: "Additional Volume Above",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        4: {
            name: "Volumes not Applicable to the Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        5: {
            name: "Matching",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        6: {
            name: "Customer Requirements",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        7: {
            name: "Draft Report",
            isDirty: false,
            isDisabled: false,
            status: "Not Complete",
        },
    });

  return (
    <>
        <div className={clsx(classes.root)}>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Allocation Mode:
                    <span className={classes.textPrimary}>{isSimulation ? "Simulation" : "Reporting"}</span>
                </Typography>
            </div>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Period:
                    <span className={classes.textPrimary}>{periodName ? periodName.year : ""}</span>
                </Typography>
            </div>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Fulfillment Type:
                    <span className={classes.textPrimary}>{isJoint ? "Joint" : "Own"}</span>
                </Typography>
            </div>
        </div>
        <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            connector={<StepConnector />}
            className={classes.stepper}
        >
            {Object.keys(steps).map((rank, index) => (
                <Step key={index}>
                    <StepButton
                        onClick={() => setActiveStep(index)}
                        disabled={steps[rank].isDisabled}
                    >
                        <StepLabel
                            StepIconComponent={(props) => (
                                <StepIcon
                                    disabled={steps[rank].isDisabled}
                                    status={steps[rank].isDirty ? steps[rank].status : null}
                                    {...props}
                                ></StepIcon>
                            )}
                        >
                            {steps[rank].name}
                        </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
        <MainCardTables
            step={activeStep}
            handleNext={() => {
                if (activeStep > 0 && !steps[activeStep + 1].isDisabled) {
                    setActiveStep(activeStep + 1);
                }}}
            handleBack={() => {
                if (activeStep > 0 && !steps[activeStep - 1].isDisabled) {
                    setActiveStep(activeStep - 1);
                }}}
        />
    </>
  );
};

export default OffRoadContainer;
