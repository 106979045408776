import React, {useEffect, useState} from "react";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import IndicatorBox from "../../../../../../containers/sustainability/multiStep/IndicatorBoxContainer";
import EditableTableContainer from "../../../../../../containers/sustainability/allocation/roadUse/EditableTable";
import MatchingTablesContainer from "../../../../../../containers/sustainability/allocation/roadUse/MatchingTables";
import OverviewPanel from "./OverviewPanel";
import OverviewTableContainer from "../../../../../../containers/sustainability/allocation/roadUse/OverviewTable";
import OverviewTableFulfillmentContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableFulfillmentContainer";
import OverviewTableConstructionContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableConstructionContainer";
import startAllocation from "../../../../../../api/sustainability/allocationSteps/startAllocation";
import {getOffRoadUseData} from "../../../../../../api/sustainability/allocation/offRoadUse/data";
import LoadingView from "./LoadingView";
import MatchingTables from "../../../../../../containers/sustainability/allocation/roadUse/MatchingTables";
import {Typography} from "@material-ui/core";
import { postStepBiotemplatesData, putStepOverViewData } from "../../../../../../api/sustainability/allocation/roadUse/stepData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    btnContainer: {
      marginBottom: theme.spacing(3),
    },
    btn: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),
      minWidth: "110px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    cardContent: {
      maxHeight: "calc(100vh - 250px)",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
  })
);

interface RoadUseCardEditableTableProps {
  step: number;
  handleNext: () => void;
  handleBack: () => void;
}

interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}


const RoadUseCardEditableTable: React.FC<RoadUseCardEditableTableProps> = ({
  step,
  handleBack,
  handleNext,
}) => {
  const classes = useStyles();
    const [biotemplates, setBiotemplates] = useState([]);
    const [stepOneBiotemplates, setStepOneBiotemplates] = useState([]);
    const [stepThirdBiotemplates, setStepThirdBiotemplates] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const fetchBiotemplateData = async () => {
        setIsFetching(true);
        const response = await startAllocation();
        if (response && response.valid) {
            setBiotemplates(response.biotemplates);
            const bensinBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.fuel_type === "Biokomponenter for bensin");
            const advancedBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.double_counted_norway === "Ja");
            setStepOneBiotemplates(bensinBiotemplates)
            setStepThirdBiotemplates(advancedBiotemplates)
            setIsFetching(false);
        } else {
            setIsFetching(false);
            alert("Some error occured");
        }
    };

    const handleTableDataUpdate = (updatedData: any[]) => {
        // @ts-ignore
        setUpdatedData(updatedData);
    };

    const clickBack = () => {
        handleBack();
    };

    const updateIsFetching = (value: boolean) => {
        setIsFetching(value);
    };

    const clickNext = async () => {
        setIsFetching(true);
        const biotemplateUpdatedData = {
            // @ts-ignore
            biotemplates : updatedData.biotemplates,
        }

        const response = await postStepBiotemplatesData(step, biotemplateUpdatedData);
        if (response && response.success) {
            handleNext();
            setIsFetching(false);
        } else {
            setIsFetching(false);
            alert("Some error occurred");
        }
    };

    useEffect(() => {
        fetchBiotemplateData();
    }, [step]);

    return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
          {isFetching ? (
              <LoadingView text={"Data is getting prepared"} />
          ) : (
              <>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  className={classes.btnContainer}
                >
                  <Grid item>
                  </Grid>
                  <Grid item>
                    {step > 0 && (
                      <ButtonRounded
                        variant="contained"
                        className={clsx(classes.btn, classes.btnDark)}
                        onClick={clickBack}
                      >
                        Back
                      </ButtonRounded>
                    )}
                    <ButtonRounded
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={clickNext}
                    >
                      Next
                    </ButtonRounded>
                  </Grid>
                </Grid>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                  >
                      <Grid item>
                          {step === 1 && (
                              <>
                                  <Typography variant="h4">Allocation of gasoline sub-mandate (4%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required volumes of biocomponents in gasoline to fulfill the gasoline sub-requirement to the road use mandate. If additional gasoline volumes are supplied to the market, this will be allocated in step 3 (advanced requirement) and/or step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                              )}
                          {step === 2 && (
                              <>
                                  <Typography variant="h4">Allocation of advanced sub-mandate (12%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required minimum volumes of advanced biocomponents (both gasoline and diesel) to fulfill the advanced sub-requirement to the road use mandate. If additional advanced volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 3 && (
                              <>
                                  <Typography variant="h4">Allocation of general mandate (17%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required volumes (both gasoline and diesel) to fulfill the general requirement to the road use mandate. If additional volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 4 && (
                              <>
                                  <Typography variant="h4">Allocation of volumes required by customers to be above the mandate</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the volumes that customers have requested to be above the mandate levels. If there are additional volumes supplied to the market that is a surplus to the mandate levels, this will be allocated in step 6.
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 5 && (
                              <>
                                  <Typography variant="h4">Allocation of additional volumes above the mandate</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for all additional volumes above the mandate levels.
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                      </Grid>
                  </Grid>
                {step === 6 && (
                    <MatchingTables/>
                )}
                  {step === 7 && (
                      <MatchingTables/>
                  )}
                  {step !== 6 && step !== 7 && (
                      <EditableTableContainer updateIsFetching={updateIsFetching} onUpdateData={handleTableDataUpdate} data={biotemplates} stepOneData={stepOneBiotemplates} stepTwoData={stepThirdBiotemplates} activeStep={step} />
                  )}

                </>
            )}
      </CardContent>
    </Card>
  );
};

export default RoadUseCardEditableTable;
