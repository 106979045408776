import React, {useEffect, useState} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../components/texts/parseData";

import SmartTable from "../../../../components/SmartTable/index";
import OverviewTableContainer from "./OverviewTable";
import OverviewPanel
  from "../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewPanel";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tableContainer: {
        overflowY: "auto",
        "& .MuiOutlinedInput-input": {
          ...theme.typography.caption,
        },
      },
      overviewPanel: {
        backgroundColor: theme.palette.common.white,
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        marginBottom: theme.spacing(-3),
      },
      table: {
        border: `1px solid ${theme.palette.grey[300]}`,
        "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
          backgroundColor: theme.palette.grey[50],
        },
        "& .MuiTableCell-root": {
          border: `1px solid ${theme.palette.grey[300]}`,
          padding: theme.spacing(1),
          ...theme.typography.caption,
        },
        "& .MuiTableCell-head": {
          color: "inherit",
          ...theme.typography.caption,
          fontWeight: 500,
        },
      },
    })
);

const tableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "raw_material_origin" },
  { name: "Biocomponent Type", id: "fuel_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "double_counted_norway" },
  { name: "Ghg Reduction", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  {
    name: "Allocated Quantity (LE)",
    alignRight: true,
    id: "allocated_eq_quantity",
  },
];

interface Props {
  data: any[];
  stepOneData: any[];
  stepTwoData: any[];
  activeStep: number;
}

const EditableTable: React.FC<Props> = ({
                                          data,
                                          stepOneData,
                                          stepTwoData,
                                          activeStep,
                                        }) => {
  const classes = useStyles();
  const [overViewData, setOverViewData] = useState(null);
  const [newData, setNewData] = useState([]);
  const [biotemplatesData, setBiotemplatesData] = useState([]);

  const getAllocated = (row: any) => row.allocated_quantity;
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    const { value } = event.target;
    const newData = { ...row, allocated_quantity: value };

    // Assuming you are updating some state here
    setNewData((prevRows) => {
      const updatedRowsCopy = [...prevRows];
      // @ts-ignore
      const rowIndex = updatedRowsCopy.findIndex((r) => r.id === row.id);

      if (rowIndex !== -1) {
        // @ts-ignore
        updatedRowsCopy[rowIndex] = newData;
      }

      return updatedRowsCopy;
    });
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    handleOnChange(event, row);
  };


  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const isDiesel = (row: any) => {
    return row.fuel_type === "Biokomponenter for diesel";
  };

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  };

  const getData = (data: any[], activeStep: number) => {
    return data;
  };

  useEffect(() => {
    // @ts-ignore
    if (activeStep === 1) {
      // @ts-ignore
      setNewData(stepOneData);
    } else if (activeStep === 2) {
      // @ts-ignore
      setNewData(stepTwoData);
    } else {
      // @ts-ignore
      setNewData(data);
    }
  }, [data, stepOneData, stepTwoData, activeStep]);

  return (
      <>
        <div className={classes.tableContainer}>
          {activeStep > 1 && (
              <>
                <Typography variant="h4">Step 2 Data</Typography><br />
                <SmartTable
                    headCells={tableHeadings}
                    rows={[]}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 2 && (
              <>
                <Typography variant="h4">Step 3 Data</Typography><br />
                <SmartTable
                    headCells={tableHeadings}
                    rows={[]}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 3 && (
              <>
                <Typography variant="h4">Step 4 Data</Typography><br />
                <SmartTable
                    headCells={tableHeadings}
                    rows={[]}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          {activeStep > 4 && (
              <>
                <Typography variant="h4">Step 5 Data</Typography><br />
                <SmartTable
                    headCells={tableHeadings}
                    rows={[]}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          <Typography variant="h4">Allocation Table</Typography><br />
          <SmartTable
              headCells={tableHeadings}
              // rows={data.length === 0 ? null : getData(data, activeStep)}
              // rows={getData(data, activeStep)}
              rows={getData(newData, activeStep)}
              editableProps={{
                id: "allocated_quantity",
                getValue: getAllocated,
                onChange: handleOnChange,
                onBlur: handleOnBlur,
              }}
              verticalOverflow={true}
              whiteHeader={true}
          />
        </div>
        <OverviewPanel className={classes.overviewPanel}>
          <OverviewTableContainer data={overViewData} step={activeStep} />
        </OverviewPanel>
      </>
  );
};

export default EditableTable;
