import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
        overflowY: "scroll",
        padding: "0 24px 50px",
        maxHeight: "200px",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: 500,
      },
    },
    green: {
      backgroundColor: "#cce6d8",
    },
    red: {
      backgroundColor: "#ffdad1",
    },
    yellow: {
      backgroundColor: "#ffd588",
    },
  })
);

const tableHeadings = [
  "Fulfillment status",
  "Requirement",
  "Required volume",
  "Joint fulfillment",
  "Allocated (LE)",
  "Allocated (L15)",
  "Surplus from off-road",
  "Remaining volume",
];

// const tableRows = new Array(10).fill(tableRow);

interface Props {
  requirement_quantities: any;
}

interface OverViewParamProps {
    etanol: number;
    etanol_advanced: number;
    fame: number;
    fame_advanced: number;
    hvo_hro: number;
    hvo_hro_advanced: number;
    nafta: number;
    nafta_advanced: number;
    bio_gasoline: number;
    bio_gasoline_advanced: number;
    general_mandate: number;
    gasoline_sub_mandate: number;
    advanced_sub_mandate: number;
    above_mandate_req_by_cust: number;
    above_mandate_after_fulfill: number;
}

interface OverViewReqParamProps {
    general_mandate: number;
    general_req_vol: number;
    general_rel_vol: number;
    advanced_sub_mandate: number;
    advanced_sub_req_vol: number;
    advanced_sub_rel_vol: number;
    gasoline_sub_mandate: number;
    gasoline_req_vol: number;
    gasoline_rel_vol: number;
    above_mandate_req_by_cust: number;
    etanol: number;
    etanol_advanced: number;
    nafta: number;
    nafta_advanced: number;
    bio_gasoline: number;
    bio_gasoline_advanced: number;
    fame: number;
    fame_advanced: number;
    hvo_hro: number;
    hvo_hro_advanced: number;
    above_mandate_after_fulfill: number;
}

interface OverViewProps {
    req_vol: OverViewReqParamProps;
    joint_fulfill: OverViewParamProps;
    allocated_LE: OverViewParamProps;
    allocated_15: OverViewParamProps;
    surplus_from_offroad: OverViewParamProps;
}

interface OverviewTableProps {
    step: number;
    data: OverViewProps;
}



const OverviewTable: React.FC<OverviewTableProps> = ({
   step,
   data
}) => {
  const classes = useStyles();

  /*  const parseVal = (value: number) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  }; */

  const getStatus = (remaining: any) => {
    if (remaining > 0) return "Not Complete";
    if (remaining < 0) return "Overallocated";
    return "Complete";
  };

  // @ts-ignore
    return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading, hidx) => (
              <TableCell key={hidx}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>

          <TableBody>
              {step > 0 && (
                  <TableRow
                      className={classes.green}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Gasoline sub-mandate ({data.req_vol.gasoline_sub_mandate} %)</TableCell>
                      <TableCell>{data.req_vol.gasoline_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_15.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.gasoline_sub_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step > 1 && (
                  <TableRow
                      className={classes.green}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Advanced sub-mandate ({data.req_vol.advanced_sub_mandate} %)</TableCell>
                      <TableCell>{data.req_vol.advanced_sub_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_15.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.advanced_sub_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step > 2 && (
                  <TableRow
                      className={classes.green}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">General mandate ({data.req_vol.general_mandate}%)</TableCell>
                      <TableCell>{data.req_vol.general_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.general_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.general_mandate}</TableCell>
                      <TableCell>{data.allocated_15.general_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.general_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step > 3 && (
                  <TableRow
                      className={classes.green}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Above the mandate (required by customers)</TableCell>
                      <TableCell>{data.req_vol.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.joint_fulfill.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.allocated_LE.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.allocated_15.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.surplus_from_offroad.above_mandate_req_by_cust}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step > 4 && (
                  <TableRow
                      className={classes.green}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Above the mandate (after mandate fulfillment)</TableCell>
                      <TableCell>{data.req_vol.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.joint_fulfill.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.allocated_LE.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.allocated_15.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.surplus_from_offroad.above_mandate_after_fulfill}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step !== 2 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Etanol</TableCell>
                  <TableCell>{data.req_vol.etanol}</TableCell>
                  <TableCell>{data.joint_fulfill.etanol}</TableCell>
                  <TableCell>{data.allocated_LE.etanol}</TableCell>
                  <TableCell>{data.allocated_15.etanol}</TableCell>
                  <TableCell>{data.surplus_from_offroad.etanol}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Etanol (advanced)</TableCell>
                  <TableCell>{data.req_vol.etanol_advanced}</TableCell>
                  <TableCell>{data.joint_fulfill.etanol_advanced}</TableCell>
                  <TableCell>{data.allocated_LE.etanol_advanced}</TableCell>
                  <TableCell>{data.allocated_15.etanol_advanced}</TableCell>
                  <TableCell>{data.surplus_from_offroad.etanol_advanced}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              {step !== 2 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Nafta</TableCell>
                  <TableCell>{data.req_vol.nafta}</TableCell>
                  <TableCell>{data.joint_fulfill.nafta}</TableCell>
                  <TableCell>{data.allocated_LE.nafta}</TableCell>
                  <TableCell>{data.allocated_15.nafta}</TableCell>
                  <TableCell>{data.surplus_from_offroad.nafta}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Nafta (advanced)</TableCell>
                  <TableCell>{data.req_vol.nafta_advanced}</TableCell>
                  <TableCell>{data.joint_fulfill.nafta_advanced}</TableCell>
                  <TableCell>{data.allocated_LE.nafta_advanced}</TableCell>
                  <TableCell>{data.allocated_15.nafta_advanced}</TableCell>
                  <TableCell>{data.surplus_from_offroad.nafta_advanced}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              {step !== 2 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Bio gasoline</TableCell>
                  <TableCell>{data.req_vol.bio_gasoline}</TableCell>
                  <TableCell>{data.joint_fulfill.bio_gasoline}</TableCell>
                  <TableCell>{data.allocated_LE.bio_gasoline}</TableCell>
                  <TableCell>{data.allocated_15.bio_gasoline}</TableCell>
                  <TableCell>{data.surplus_from_offroad.bio_gasoline}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">Bio gasoline (advanced)</TableCell>
                  <TableCell>{data.req_vol.bio_gasoline_advanced}</TableCell>
                  <TableCell>{data.joint_fulfill.bio_gasoline_advanced}</TableCell>
                  <TableCell>{data.allocated_LE.bio_gasoline_advanced}</TableCell>
                  <TableCell>{data.allocated_15.bio_gasoline_advanced}</TableCell>
                  <TableCell>{data.surplus_from_offroad.bio_gasoline_advanced}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              {step !== 1 && step !== 2 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">FAME</TableCell>
                  <TableCell>{data.req_vol.fame}</TableCell>
                  <TableCell>{data.joint_fulfill.fame}</TableCell>
                  <TableCell>{data.allocated_LE.fame}</TableCell>
                  <TableCell>{data.allocated_15.fame}</TableCell>
                  <TableCell>{data.surplus_from_offroad.fame}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              {step !== 1 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">FAME (advanced)</TableCell>
                  <TableCell>{data.req_vol.fame_advanced}</TableCell>
                  <TableCell>{data.joint_fulfill.fame_advanced}</TableCell>
                  <TableCell>{data.allocated_LE.fame_advanced}</TableCell>
                  <TableCell>{data.allocated_15.fame_advanced}</TableCell>
                  <TableCell>{data.surplus_from_offroad.fame_advanced}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              {step !== 1 && step !== 2 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">HVO/HRO</TableCell>
                  <TableCell>{data.req_vol.hvo_hro}</TableCell>
                  <TableCell>{data.joint_fulfill.hvo_hro}</TableCell>
                  <TableCell>{data.allocated_LE.hvo_hro}</TableCell>
                  <TableCell>{data.allocated_15.hvo_hro}</TableCell>
                  <TableCell>{data.surplus_from_offroad.hvo_hro}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              {step !== 1 && (
              <TableRow
                  // className={classes.green}
              >
                  <TableCell>OK</TableCell>
                  <TableCell variant="head">HVO/HRO (advanced)</TableCell>
                  <TableCell>{data.req_vol.hvo_hro_advanced}</TableCell>
                  <TableCell>{data.joint_fulfill.hvo_hro_advanced}</TableCell>
                  <TableCell>{data.allocated_LE.hvo_hro_advanced}</TableCell>
                  <TableCell>{data.allocated_15.hvo_hro_advanced}</TableCell>
                  <TableCell>{data.surplus_from_offroad.hvo_hro_advanced}</TableCell>
                  <TableCell>0</TableCell>
              </TableRow>
              )}
              {step < 1 && (
                  <TableRow
                      className={classes.red}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Gasoline sub-mandate ({data.req_vol.gasoline_sub_mandate} %)</TableCell>
                      <TableCell>{data.req_vol.gasoline_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_15.gasoline_sub_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.gasoline_sub_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step < 2 && (
                  <TableRow
                      className={classes.red}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Advanced sub-mandate ({data.req_vol.advanced_sub_mandate} %)</TableCell>
                      <TableCell>{data.req_vol.advanced_sub_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.allocated_15.advanced_sub_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.advanced_sub_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step < 3 && (
                  <TableRow
                      className={classes.red}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">General mandate ({data.req_vol.general_mandate} %)</TableCell>
                      <TableCell>{data.req_vol.general_req_vol}</TableCell>
                      <TableCell>{data.joint_fulfill.general_mandate}</TableCell>
                      <TableCell>{data.allocated_LE.general_mandate}</TableCell>
                      <TableCell>{data.allocated_15.general_mandate}</TableCell>
                      <TableCell>{data.surplus_from_offroad.general_mandate}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step < 4 && (
                  <TableRow
                      className={classes.red}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Above the mandate (required by customers)</TableCell>
                      <TableCell>{data.req_vol.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.joint_fulfill.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.allocated_LE.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.allocated_15.above_mandate_req_by_cust}</TableCell>
                      <TableCell>{data.surplus_from_offroad.above_mandate_req_by_cust}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
              {step < 5 && (
                  <TableRow
                      className={classes.red}
                  >
                      <TableCell>OK</TableCell>
                      <TableCell variant="head">Above the mandate (after mandate fulfillment)</TableCell>
                      <TableCell>{data.req_vol.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.joint_fulfill.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.allocated_LE.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.allocated_15.above_mandate_after_fulfill}</TableCell>
                      <TableCell>{data.surplus_from_offroad.above_mandate_after_fulfill}</TableCell>
                      <TableCell>0</TableCell>
                  </TableRow>
              )}
          </TableBody>
      </Table>
    </div>
  );
};

export default OverviewTable;
