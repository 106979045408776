import React, {Fragment, useEffect, useState} from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import AllocStepsStatusTable from "../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/AllocStepsStatusTable";
import sustainabilityPool from "../../../../api/sustainability/allocationSteps/sustainabilityPool";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { overflowY: "auto" },
  })
);

interface AllocationStatusVolumeProps {
  sold_volume: any,
  allocated_volume: any,
  net_volume: any
}

interface AllocationStatusTonEquivalenceProps {
  sold_ton_equivalence: any,
  allocated_ton_equivalence: any,
  net_ton_equivalence: any
}

interface Batch {
  id: number,
  fuel_type: string,
  biofuel_type: string,
  raw_material: string,
  raw_material_origin: string,
  double_counted_norway: string,
  reduction_in_climate_emission: string,
  volume: string,
  mj_liter: string,
  emissions_ton: string,
  fossil_ton: string,
  ghg_reduction_ton: string,
  ghg_reduction_percentage: string,
}

const MatchingTables: React.FC = () => {
  const classes = useStyles();
  const [sustainableBatches, setSustainableBatches] = useState([]);
  const [allocationStatusVolume, setAllocationStatusVolume] = useState<AllocationStatusVolumeProps>({
    'sold_volume': 0,
    'allocated_volume': 0,
    'net_volume': 0,
  });
  const [allocationStatusTonEquivalence, setAllocationStatusTonEquivalence] = useState<AllocationStatusTonEquivalenceProps>({
    'sold_ton_equivalence': 0,
    'allocated_ton_equivalence': 0,
    'net_ton_equivalence': 0
  });

  const volumeHeaders = [
    "Component",
    "Sold Volume",
    "Allocated Volume",
    "To be allocated Volume",
    "Status",
  ];

  const {
    sold_volume,
    allocated_volume,
    net_volume,
  } = allocationStatusVolume;

  const volumeRows = [
    [
      "Etanol",
      sold_volume.sold_etanol,
      allocated_volume.allocated_etanol,
      net_volume.net_etanol,
      net_volume.net_etanol_status,
    ],
    [
      "Nafta",
      sold_volume.sold_nafta,
      allocated_volume.allocated_nafta,
      net_volume.net_nafta,
      net_volume.net_nafta_status,
    ],
    [
      "FAME",
      sold_volume.sold_fame,
      allocated_volume.allocated_fame,
      net_volume.net_fame,
      net_volume.net_fame_status,
    ],
    [
      "HVO/HRO",
      sold_volume.sold_hvo_hro || sold_volume.sold_hvo,
      allocated_volume.allocated_hvo_hro || allocated_volume.allocated_hvo,
      net_volume.net_hvo_hro || net_volume.net_hvo,
      net_volume.net_hvo_hro_status || net_volume.net_hvo_status,
    ],
  ];

  const tonHeaders = [
    "Component",
    "Sold tCO2e",
    "Allocated tCO2e",
    "To be allocated tCO2e",
    "Status",
  ];

  const {
    sold_ton_equivalence,
    allocated_ton_equivalence,
    net_ton_equivalence,
  } = allocationStatusTonEquivalence;

  const tonRows = [
    [
      "Etanol",
      sold_ton_equivalence.sold_etanol,
      allocated_ton_equivalence.allocated_etanol,
      net_ton_equivalence.net_etanol,
      net_ton_equivalence.net_etanol_status,
    ],
    [
      "Nafta",
      sold_ton_equivalence.sold_nafta,
      allocated_ton_equivalence.allocated_nafta,
      net_ton_equivalence.net_nafta,
      net_ton_equivalence.net_nafta_status,
    ],
    [
      "FAME",
      sold_ton_equivalence.sold_fame,
      allocated_ton_equivalence.allocated_fame,
      net_ton_equivalence.net_fame,
      net_ton_equivalence.net_fame_status,
    ],
    [
      "HVO/HRO",
      sold_ton_equivalence.sold_hvo_hro || sold_ton_equivalence.sold_hvo,
      allocated_ton_equivalence.allocated_hvo_hro ||
        allocated_ton_equivalence.allocated_hvo,
      net_ton_equivalence.net_hvo_hro || net_ton_equivalence.net_hvo,
      net_ton_equivalence.net_hvo_hro_status ||
        net_ton_equivalence.net_hvo_status,
    ],
  ];

  const poolHeaders = [
    "Sustainability criteria",
    "Energy content & emissions",
    "Emission red.",
  ];

  const poolSubHeaders = [
    [
      "Sus. batch",
      "Product type",
      "Biofuel type",
      "Raw material",
      "Raw material origin",
      "Advanced",
      "Ghg red.",
      "Volume",
    ],
    ["MJ", "WTW tCO2e", "Fossil ref. tCO2e"],
    ["Ghg red. tCO2e", "Ghg red."],
  ];

  const getSustainableBatches: Batch[] = sustainableBatches;

  const poolRows = Array.isArray(getSustainableBatches)
    ? getSustainableBatches.map((b) => {
        return [
          b.id,
          b.fuel_type,
          b.biofuel_type,
          b.raw_material,
          b.raw_material_origin,
          b.double_counted_norway,
          b.reduction_in_climate_emission,
          b.volume,
          b.mj_liter,
          b.emissions_ton,
          b.fossil_ton,
          b.ghg_reduction_ton,
          b.ghg_reduction_percentage,
        ];
      })
    : [];

  const getMatchedData = async () => {
    const response = await sustainabilityPool();
    if (response && response.valid) {
      setSustainableBatches(response.sustainable_batches);
      setAllocationStatusTonEquivalence(response.allocation_status_ton_equivalence);
      setAllocationStatusVolume(response.allocation_status_volume);
    } else {
      setSustainableBatches([]);
      setAllocationStatusTonEquivalence({
        'sold_ton_equivalence': 0,
        'allocated_ton_equivalence': 0,
        'net_ton_equivalence': 0
      });
      setAllocationStatusVolume({
        'sold_volume': 0,
        'allocated_volume': 0,
        'net_volume': 0,
      });
    }

  };

  useEffect(() => {
    getMatchedData();
  }, []);

  return (
    <div className={classes.root}>
      <AllocStepsStatusTable
        headers={["Status Volume"]}
        subHeaders={[volumeHeaders]}
        rows={volumeRows}
      />
      <AllocStepsStatusTable
        headers={["Status tCO2e"]}
        subHeaders={[tonHeaders]}
        rows={tonRows}
      />
      <AllocStepsStatusTable
        headers={poolHeaders}
        subHeaders={poolSubHeaders}
        // @ts-ignore
        rows={poolRows}
        percentageIndexes={{ 6: true, 12: true }}
      />
    </div>
  );
};

export default MatchingTables;
