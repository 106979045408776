import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { FORM_ERROR } from "final-form";
import moment from "moment";
import React, {useState, useEffect} from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import SmartMultiSelect from "../../components/SmartMultiSelect";
import SmartSelect from "../../components/SmartSelect";
import parseData from "../../components/texts/parseData";
import ButtonRounded from "../ButtonRounded";
import ModalContainer from "../ModalContainer";
import TextField from "../TextFieldSpread";
import makeValidate from "../form/makeValidate";
import KeyboardDatePicker from "../inputs/KeyboardDatePicker";
import { IModalAddEditProps } from "./Interfaces";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      paddingTop: theme.spacing(2),
    },
    sectionDivider: {
      paddingTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
    },
    btnContainer: {
      paddingTop: theme.spacing(6),
    },
    red: {
      color: theme.palette.error.main,
    },
    select: {
      color: theme.palette.primary.main,
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);
const getDatePicker = (id: string, values: any, disabled: boolean) => {
  switch (id) {
    case "start_date":
      return (
        <Field name={id}>
          {({ input, meta }) => (
            <KeyboardDatePicker
              {...input}
              inputVariant="outlined"
              format="YYYY-MM-DD"
              maxDate={values.end_date}
              placeholder={moment().format("YYYY-MM-DD")}
              value={input.value ? input.value : null}
              onChange={(value: any) => {
                input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
              }}
              invalidDateMessage={null}
              fullWidth
              disabled={disabled}
            />
          )}
        </Field>
      );
    case "end_date":
      return (
        <Field name={id}>
          {({ input, meta }) => (
            <KeyboardDatePicker
              {...input}
              inputVariant="outlined"
              format="YYYY-MM-DD"
              minDate={values.start_date}
              placeholder={moment().format("YYYY-MM-DD")}
              value={input.value ? input.value : null}
              onChange={(value: any) => {
                input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
              }}
              invalidDateMessage={null}
              fullWidth
              disabled={disabled}
            />
          )}
        </Field>
      );
    default:
      return (
        <Field name={id}>
          {({ input, meta }) => (
            <KeyboardDatePicker
              {...input}
              inputVariant="outlined"
              format="YYYY-MM-DD"
              placeholder={moment().format("YYYY-MM-DD")}
              invalidDateMessage={null}
              value={input.value ? input.value : null}
              onChange={(value: any) => {
                input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
              }}
              fullWidth
              disabled={disabled}
            />
          )}
        </Field>
      );
  }
};
const getAutoFill = (
  values: any,
  autofill: any[],
  decimals: number,
  name: string,
  initValues?: any
) => {
  if (!autofill) {
    if (initValues && initValues[name] != null) {
      if (decimals) return parseData(initValues[name], decimals);
      else return initValues[name];
    } else return "";
  }
  const numbers = autofill.map((val) => {
    if (typeof val === "string") {
      var number: number = 0;
      const numberString = val.split("-");
      if (numberString.length > 1 && parseFloat(values[numberString[1]]))
        number =
          -1 * parseFloat(values[numberString[1]].toString().replace(",", "."));
      else if (parseFloat(values[val]))
        number = parseFloat(values[val].toString().replace(",", "."));
      return number;
    } else if (typeof val === "number") return val;
    return 0;
  });
  const result = numbers.reduce((acc, no) => acc + no);
  return decimals ? parseData(result, decimals) : result;
};
const fieldIsVisible = (condition: any, values: any) => {
  if (
    condition &&
    ((condition.value && values[condition.id] != condition.value) ||
      (!condition.value && !values[condition.id]))
  )
    return false;
  else return true;
};
const setHiddenValues = (args: any, state: any) => {
  state.fields[args[1]].change(args[2]);
  let form = args[3];
  const hidden_elements = args[0].filter(
    (elmt: any) =>
      elmt.visibility_condition &&
      elmt.visibility_condition.id === args[1] &&
      elmt.visibility_condition.value &&
      elmt.visibility_condition.value != args[2]
  );
  /* const displayed_elements = args[0].filter(
    (elmt: any) =>
      elmt.visibility_condition &&
      elmt.visibility_condition.id === args[1] &&
      elmt.visibility_condition.value &&
      elmt.visibility_condition.value === args[2]
  ); */
  hidden_elements.map((elmt: any) => {
    const field = state.fields[elmt.id];
    if (!field)
      form.registerField(
        elmt.id,
        (fieldState: any) => {
          const { blur, change, focus, ...rest } = fieldState;
        },
        {
          active: true,
          dirty: true,
          touched: true,
          valid: true,
          value: true,
        }
      );
    state.fields[elmt.id].change(0);
  });
  /* displayed_elements.map((elmt: any) => {
    const field = state.fields[elmt.id];
    if (!field)
      form.registerField(
        elmt.id,
        (fieldState: any) => {
          const { blur, change, focus, ...rest } = fieldState;
        },
        {
          active: true,
          dirty: true,
          touched: true,
          valid: true,
          value: true,
        }
      );
    form.resetFieldState(elmt.id);
    state.fields[elmt.id].change(0);
  }); */
};
const ModalAddEdit: React.FC<IModalAddEditProps> = ({
  name,
  mandateType,
  open,
  onSave,
  editParams,
  initParams,
  params,
  onClose,
}) => {
  const [advancedData, setAdvancedData] = useState<string>('');
  const [updatedParams, setUpdatedParams] = useState<any>([]);
  const classes = useStyles();
  const getValidationSchema = () => {
    let optionnalFields = {};
    Array.isArray(updatedParams) &&
    updatedParams.map((param: any) => {
        if (!param.optionnal && !param.hide) {
          Object.assign(optionnalFields, {
            [param.id]: yup.string().nullable().required(),
          });
        }


console.error(optionnalFields);
      // if (param.id === "raw_material_type" || param.id === "advanced_tax_years") {
        //   if (advancedData !== "No") {
        //     Object.assign(optionnalFields, {
        //       ["raw_material_type"]: yup.string().nullable().required(),
        //     });
        //   } else {
        //     if (optionnalFields.hasOwnProperty("raw_material_type")) {
        //       delete optionnalFields["raw_material_type"];
        //     }
        //   }
        //   if (advancedData === "Partly") {
        //     Object.assign(optionnalFields, {
        //       ["advanced_tax_years"]: yup.string().nullable().required(),
        //     });
        //   } else {
        //     if (optionnalFields.hasOwnProperty("advanced_tax_years")) {
        //       delete optionnalFields["advanced_tax_years"];
        //     }
        //   }
        // }
      });
    return yup.object(optionnalFields);
  };
  const onSubmit = async (values: any) => {
    let res = null;
    let formattedValues = { ...values };
    // if Edit Mode: give selected setting id to onSave parameters
    Object.keys(formattedValues).forEach((key) => {
      if (moment.isMoment(formattedValues[key])) {
        formattedValues[key] = formattedValues[key].format("YYYY-MM-DD");
      } else if (key === "advanced_tax_years") {
        if (advancedData === "Partly") {
          if (Array.isArray(formattedValues[key])) {
            formattedValues[key] = formattedValues[key].sort();
            formattedValues[key] = formattedValues[key].join(",");
          } else {
            if (formattedValues[key]) {
              formattedValues[key] = formattedValues[key].toString();
            } else {
              formattedValues[key] = null;
            }
          }
        } else {
          formattedValues[key] = null;
        }
      } else if (key === "raw_material_type") {
        if (advancedData === "No") {
          formattedValues[key] = null;
        } else {
          formattedValues[key] = formattedValues[key].toString();
        }
      }
      else if (parseFloat(formattedValues[key]) && !isNaN(parseFloat(formattedValues[key]))) {
        formattedValues[key] = formattedValues[key].toString().replace(",", ".");
      }
    });

    formattedValues['mandate_type'] = mandateType;

    if (editParams) { res = await onSave(formattedValues, editParams.id); } //Edit Mode
    else {
      res = await onSave(formattedValues); // Create Mode
    }
    if (res && res.valid) {
      onClose();
    } else {
      return res && typeof res.message === "string"
        ? { [FORM_ERROR]: res.message }
        : res.message;
    }
  };

  useEffect(() => {
    const newParams = params.map(param => {
      if (param.id === 'raw_material_type') {
        return { ...param, optionnal: advancedData === "No" };
      }
      if (param.id === 'advanced_tax_years') {
        return { ...param, optionnal: advancedData !== "Partly" };
      }

      return param;
    });

    setUpdatedParams(newParams);

  }, [advancedData]);

  useEffect(() => {
    const newParams = params.map(param => {

      if (param.id === 'biofuel_requirement') {
        if (mandateType != 'road-use') {
          return { ...param, optionnal: true };
        } else {
          return { ...param, decimals_format: 2 };
        }
      }

      if (param.id === 'bioethanol_sub') {
        if (mandateType != 'road-use') {
          return { ...param, optionnal: true };
        } else {
          return { ...param, decimals_format: 2 };
        }
      }

      return param;
    });

    setUpdatedParams(newParams);

  }, [mandateType, params]);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        editParams ? (
          <FormattedMessage id="settingsView.modalAddEdit.title.edit" />
        ) : (
          <FormattedMessage id="settingsView.modalAddEdit.title.addNew" />
        )
      }
      subtitle={name}
    >
      <div>
        <div>
          <Form
            onSubmit={onSubmit}
            validate={(values) => makeValidate(values, getValidationSchema())}
            mutators={{ setHiddenValues }}
            initialValues={editParams ? editParams : initParams}
          >
            {({
              form,
              handleSubmit,
              submitting,
              hasValidationErrors,
              submitError,
              values,
              initialValues,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                {Array.isArray(updatedParams) &&
                updatedParams.map(
                    (param: any, index: number) =>
                      !param.hide &&
                      fieldIsVisible(param.visibility_condition, values) && (
                        <div key={param.id + index}>
                          {(param.section_start || param.section_middle) && (
                            <Typography
                              variant="h6"
                              className={classes.sectionDivider}
                              gutterBottom
                            >
                              {param.section.name}
                            </Typography>
                          )}
                          <Typography
                            variant="body1"
                            className={classes.marginTop}
                            gutterBottom
                          >
                            {param.name}
                            {param.optionnal ? (
                              ""
                            ) : (
                              <span className={classes.red}> * </span>
                            )}
                          </Typography>
                          {param.type === "text" && (
                            <Field
                              component={TextField}
                              name={param.id}
                              variant={"outlined"}
                              fullWidth
                              inputProps={{
                                maxLength: 800,
                                placeholder: "0"
                              }}
                              format={(value: any) =>
                                param.decimals_format
                                  ? parseData(value, param.decimals_format)
                                  : value
                              }
                              formatOnBlur
                              disabled={(mandateType=='off-road' || mandateType == 'marine') && (param.id == 'biofuel_requirement' || param.id == 'bioethanol_sub')}
                              initialValue={
                                ((mandateType === 'marine' || mandateType === 'off-road') && (param.id === 'biofuel_requirement' || param.id === 'bioethanol_sub'))
                                    ? 0
                                    : getAutoFill(values, param.autofill, param.decimals_format, param.id, initialValues)
                              }
                              value={
                              getAutoFill(
                                values,
                                param.autofill,
                                param.decimals_format,
                                param.id
                              )}
                            />
                          )}
                          {param.type === "date" &&
                            getDatePicker(param.id, values, param.disabled)}
                          {param.type === "select" && (
                              <>
                              {param.id === "advanced_type" && (
                                <FormControl fullWidth>
                                  <Field name={param.id}>
                                    {(props) => {
                                      return (
                                          <SmartSelect
                                              {...props.input}
                                              fullWidth
                                              displayEmpty
                                              type={param.params}
                                              disabled={param.disabled}
                                              filterValue={param.filter_value}
                                              onChange={(e: any) => {
                                                setAdvancedData(e.target.value)
                                                  form.mutators.setHiddenValues(
                                                      params,
                                                      e.target.name,
                                                      e.target.value,
                                                      form
                                                  )
                                              }}
                                          />
                                      );
                                    }}
                                  </Field>
                                </FormControl>
                              )}
                              {param.id === "raw_material_type" && (
                                  <FormControl fullWidth>
                                    <Field name={param.id}>
                                      {(props) => {
                                        return (
                                            <SmartSelect
                                                {...props.input}
                                                fullWidth
                                                displayEmpty
                                                type={param.params}
                                                disabled={advancedData === "No"}
                                                filterValue={param.filter_value}
                                                onChange={(e: any) => {
                                                  form.mutators.setHiddenValues(
                                                      params,
                                                      e.target.name,
                                                      e.target.value,
                                                      form
                                                  )
                                                }}
                                            />
                                        );
                                      }}
                                    </Field>
                                  </FormControl>
                              )}
                              {param.id !== "advanced_type"
                              && param.id !== "raw_material_type" && (
                                  <FormControl fullWidth>
                                    <Field name={param.id}>
                                      {(props) => {
                                        return (
                                            <SmartSelect
                                                {...props.input}
                                                fullWidth
                                                displayEmpty
                                                type={param.params}
                                                disabled={param.disabled}
                                                filterValue={param.filter_value}
                                                onChange={(e: any) => {
                                                  form.mutators.setHiddenValues(
                                                      params,
                                                      e.target.name,
                                                      e.target.value,
                                                      form
                                                  )
                                                }}
                                            />
                                        );
                                      }}
                                    </Field>
                                  </FormControl>
                              )}
                              </>
                          )}
                          {param.type === "multi-select" && (
                              <>
                                {param.id === "advanced_tax_years" && (
                                    <FormControl fullWidth>
                                      <Field name={param.id}>
                                        {(props) => {
                                          return (
                                              <SmartMultiSelect
                                                  {...props.input}
                                                  fullWidth
                                                  displayEmpty
                                                  type={param.params}
                                                  disabled={advancedData !== "Partly"}
                                                  filterValue={param.filter_value}
                                                  onChange={(e: any) => {
                                                    form.mutators.setHiddenValues(
                                                        params,
                                                        e.target.name,
                                                        e.target.value,
                                                        form
                                                    )
                                                  }
                                                  }
                                              />
                                          );
                                        }}
                                      </Field>
                                    </FormControl>
                                )}


                                {param.id !== "advanced_tax_years" && (
                                    <FormControl fullWidth>
                                      <Field name={param.id}>
                                        {(props) => {
                                          return (
                                              <SmartMultiSelect
                                                  {...props.input}
                                                  fullWidth
                                                  displayEmpty
                                                  type={param.params}
                                                  disabled={param.disabled}
                                                  filterValue={param.filter_value}
                                                  onChange={(e: any) =>
                                                    form.mutators.setHiddenValues(
                                                        params,
                                                        e.target.name,
                                                        e.target.value,
                                                        form
                                                    )
                                                  }
                                              />
                                          );
                                        }}
                                      </Field>
                                    </FormControl>
                                )}
                              </>
                          )}
                          {param.type === "boolean" && (
                            <FormControl fullWidth>
                              <Field name={param.id}>
                                {(props) => {
                                  // @ts-ignore
                                  return (
                                    <Select
                                      {...props.input}
                                      displayEmpty
                                      input={<OutlinedInput labelWidth={0} />}
                                      className={clsx(classes.select, {
                                        [classes.greyText]: props.input.value === "",
                                      })}
                                    >
                                      <MenuItem value="">
                                        {(param.params.length < 1) || (
                                          <FormattedMessage id="dropdown.placeholder.select" />
                                        )}
                                      </MenuItem>
                                      {param.params.map((option: any) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>

                                  );
                                }}
                              </Field>
                            </FormControl>
                          )}
                        </div>
                      )
                  )}
                {submitError && (
                  <Typography align="center" variant="body2" color="error">
                    {submitError}
                  </Typography>
                )}
                <Grid container spacing={2} className={classes.btnContainer}>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onClose}
                    >
                      {<FormattedMessage id="settingsView.modal.cancel" />}
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      {<FormattedMessage id="settingsView.modal.save" />}
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>
      </div>
    </ModalContainer>
  );
};
export default ModalAddEdit;


