import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            padding: theme.spacing(3),
            paddingTop: 0,
        },
        table: {
            border: `1px solid ${theme.palette.grey[300]}`,
            // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
            //   backgroundColor: theme.palette.grey[50],
            // },
            "& .MuiTableCell-root": {
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: theme.spacing(1),
                ...theme.typography.body2,
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontWeight: 500,
            },
        },
        green: {
            backgroundColor: "#cce6d8",
        },
        red: {
            backgroundColor: "#ffdad1",
        },
        yellow: {
            backgroundColor: "#ffd588",
        },
    })
);

const tableHeadings = [
    "Fulfillment status",
    "Requirement",
    "Required volume",
    "Joint fulfillment",
    "Allocated (LE)",
    "Allocated (L15)",
    "Surplus from off-road",
    "Remaining volume",
];

// const tableRows = new Array(10).fill(tableRow);

interface Props {
    requirement_quantities: any;
}

interface OverviewTableProps {
    step: number;
    data: any;
}

const OverviewTable: React.FC<OverviewTableProps> = ({
                                                         step,
                                                         data
                                                     }) => {
    const classes = useStyles();

    const rows = [
        {
            status: "Sold volume remaining",
            title: "Etanol",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Sold volume remaining",
            title: "Etanol (advanced)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Sold volume remaining",
            title: "Nafta",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Sold volume remaining",
            title: "Nafta (advanced)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Sold volume remaining",
            title: "Bio gasoline",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Sold volume remaining",
            title: "Bio gasoline (advanced)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "Mandate fulfilled",
            title: "Gasoline sub-mandate (4 %)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "0.00",
        },
        {
            status: "NB! NOT fulfilled",
            title: "Advanced sub-mandate (12,5 %)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "NB! NOT fulfilled",
            title: "General mandate (17 %)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "NB! NOT fulfilled",
            title: "Bio volumes above the mandate (required by customers)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
        {
            status: "NB! NOT fulfilled",
            title: "Bio volumes above the mandate (after mandate fulfillment)",
            req_vol: "0.00",
            joint_fulfillment: "0.00",
            allocated_le: "0.00",
            allocated_l15: "0.00",
            surplus_offroad: "0.00",
            remaining: "1000",
        },
    ];

    /*  const parseVal = (value: number) => {
      let parsed = value;
      if (parsed) {
        if (value > -1 && value < 0) parsed *= -1;
        return parseData(parsed, 2);
      } else return parsed;
    }; */

    const getStatus = (remaining: any) => {
        if (remaining > 0) return "Not Complete";
        if (remaining < 0) return "Overallocated";
        return "Complete";
    };

    return (
        <div className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {tableHeadings.map((heading, hidx) => (
                            <TableCell key={hidx}>{heading}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row, ridx) => (
                        <TableRow
                            key={ridx}
                            className={clsx({
                                [classes.green]: getStatus(row.remaining) === "Complete",
                                [classes.red]: getStatus(row.remaining) === "Not Complete",
                                [classes.yellow]: getStatus(row.remaining) === "Overallocated",
                            })}
                        >
                            <TableCell>{row.status}</TableCell>
                            <TableCell variant="head">{row.title}</TableCell>
                            <TableCell>{row.req_vol}</TableCell>
                            <TableCell>{row.joint_fulfillment}</TableCell>
                            <TableCell>{row.allocated_le}</TableCell>
                            <TableCell>{row.allocated_l15}</TableCell>
                            <TableCell>{row.surplus_offroad}</TableCell>
                            <TableCell>{row.remaining}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default OverviewTable;
